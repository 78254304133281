import React, { useEffect, useState } from "react"
import { getAdminDepartments, getAdminFloorPlans, getAdminLocation, getAllCompanies, getAllDepartments, getAllProducts, getMeetingRooms, getViolationTypes } from "../../services/ApiServices"
import { changeAPIObject, changeAllFloorPlans, changeAllDepartments, changeAdminDepartments } from "../../store/slices/APIResponseSlice"
import { useDispatch, useSelector } from "react-redux"
import { sortResponseByKey } from "../../const"
import ReactDatePicker from "react-datepicker"
import moment from "moment"
import "react-datepicker/dist/react-datepicker.css"
import "./SideBar.css"

export default function SideBar() {
  const activePanel = useSelector((state) => state.APIResponse.ActivePanel)
  const hasMeetingRoomPermission = useSelector((state) => state.APIResponse.EnableMeetingRoom)
  const hasCateringPermission = useSelector((state) => state.APIResponse.EnableCanteen)

  const [allLocations, setAllLocations] = useState([])
  const [allFloorPlans, setAllFloorPlans] = useState([])
  const [allMeetingRooms, setAllMeetingRooms] = useState([])
  const [allAreas, setAllAreas] = useState([])
  const [allDepartments, setAllDepartments] = useState([])
  const [allProducts, setAllProducts] = useState([])
  const [allCompanies, setAllCompanies] = useState([])
  const [adminDepartments, setAdminDepartments] = useState([])

  const [filteredLocations, setFilteredLocations] = useState([])
  const [filteredFloorPlans, setFilteredFloorPlans] = useState([])
  const [filteredMeetingRooms, setFilteredMeetingRooms] = useState([])
  const [filteredAreas, setFilteredAreas] = useState([])

  const [locationCount, setLocationCount] = useState(4)
  const [floorCount, setFloorCount] = useState(4)
  const [filteredMeetingRoomsCount, setFilteredMeetingRoomsCount] = useState(4)
  const [areaCount, setAreaCount] = useState(4)
  const [costCenterCount, setCostCenterCount] = useState(4)
  const [departmentCount, setDepartmentCount] = useState(4)
  const [productCount, setProductCount] = useState(4)
  const [companiesCount, setCompaniesCount] = useState(4)

  const [inputDate, setInputDate] = useState({ From: moment().subtract(1, "M").format("YYYY-MM-DD"), To: moment().format("YYYY-MM-DD") })
  const [selectedLocations, setSelectedLocation] = useState([])
  const [selectedFloorPlans, setSelectedFloorPlans] = useState([])
  const [selectedMeetingRooms, setSelectedMeetingRooms] = useState([])
  const [selectedAreas, setSelectedAreas] = useState([])
  const [selectedCostCenter, setSelectedCostCenter] = useState([])
  const [selectedDepartments, setSelectedDepartments] = useState([])
  const [selectedCompanies, setSelectedCompanies] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])

  const dispatch = useDispatch()

  const ApiObjectState = useSelector((state) => state?.APIResponse?.ApiObject)

  const handleOnLocationClick = (locationId, selectedLocations, selectedFloorPlans, selectedMeetingRooms, selectedAreas) => {
    let newSelectedLocations = []
    if (!selectedLocations.includes(locationId)) {
      newSelectedLocations = [...selectedLocations, locationId]
    } else if (selectedLocations.includes(locationId)) {
      newSelectedLocations = selectedLocations.filter((d) => d !== locationId)
    }
    // =======================================================================================
    // =======================================================================================
    const newFilteredFloorPlans = allFloorPlans && allFloorPlans.length > 0 ?
      newSelectedLocations.length > 0 ?
        allFloorPlans.filter((d) => newSelectedLocations.includes(d?.Location?.Id))
        : allFloorPlans
      : []
    let newSelectedFloorPlans = []
    newFilteredFloorPlans.forEach((floorPlan) => {
      selectedFloorPlans.includes(floorPlan?.Id) && newSelectedFloorPlans.push(floorPlan?.Id)
    })
    // =======================================================================================
    // =======================================================================================
    let newFilteredMeetingRooms = []
    if (allMeetingRooms && allMeetingRooms.length > 0) {
      if (newSelectedLocations.length === 0 && selectedFloorPlans.length === 0) {
        newFilteredMeetingRooms = allMeetingRooms
      }

      if (newSelectedLocations.length > 0 && selectedFloorPlans.length === 0) {
        newFilteredMeetingRooms = allMeetingRooms.filter((d) => newSelectedLocations.includes(d?.Location?.Id))
      }

      if ((newSelectedLocations.length === 0 || newSelectedLocations.length > 0) && selectedFloorPlans.length > 0) {
        const tempSelectedFloorPlans = allFloorPlans.filter((d) => selectedFloorPlans.includes(d?.Id))
        tempSelectedFloorPlans.forEach((floorPlan) => {
          floorPlan?.FloorplanMeetingRooms && floorPlan?.FloorplanMeetingRooms.length > 0 && newFilteredMeetingRooms.push(...floorPlan?.FloorplanMeetingRooms)
        })
      }
    } else {
      newFilteredMeetingRooms = []
    }
    let newSelectedMeetingRooms = []
    newFilteredMeetingRooms.forEach((meetingRoom) => {
      selectedMeetingRooms.includes(meetingRoom?.Id) && newSelectedMeetingRooms.push(meetingRoom?.Id)
    })
    // =======================================================================================
    // =======================================================================================
    let newFilteredAreas = []
    if (newSelectedLocations.length === 0 && selectedFloorPlans.length === 0) {
      newFilteredAreas = allAreas && allAreas.length > 0 ? allAreas : []
    }
    if (newSelectedLocations.length > 0 && selectedFloorPlans.length === 0) {
      newFilteredAreas = allAreas && allAreas.length > 0 ? allAreas.filter((d) => newSelectedLocations.includes(d?.Location?.Id)) : []
    }
    if ((newSelectedLocations.length === 0 || newSelectedLocations.length > 0) && selectedFloorPlans.length > 0) {
      const tempSelectedFloorPlans = allFloorPlans.filter((d) => selectedFloorPlans.includes(d?.Id))
      tempSelectedFloorPlans.forEach((floorPlan) => {
        if (floorPlan?.FloorplanAreas && floorPlan?.FloorplanAreas.length > 0) {
          floorPlan?.FloorplanAreas.forEach((area) => {
            newFilteredAreas.push(area?.Area)
          })
        }
      })
    }
    let newSelectedAreas = []
    newFilteredAreas.forEach((meetingRoom) => {
      selectedAreas.includes(meetingRoom?.Id) && newSelectedAreas.push(meetingRoom?.Id)
    })
    // =======================================================================================
    // =======================================================================================

    setSelectedLocation(newSelectedLocations)
    setSelectedFloorPlans(newSelectedFloorPlans)
    setSelectedMeetingRooms(newSelectedMeetingRooms)
    setSelectedAreas(newSelectedAreas)

    setFilteredFloorPlans(sortResponseByKey(newFilteredFloorPlans, "Name"))
    setFilteredMeetingRooms(sortResponseByKey(newFilteredMeetingRooms, "Name"))
    setFilteredAreas(sortResponseByKey(newFilteredAreas, "Name"))
  }

  const handleOnFloorClick = (floorId, selectedLocations, selectedFloorPlans, selectedMeetingRooms, selectedAreas) => {
    let newSelectedFloorPlan = []
    if (!selectedFloorPlans.includes(floorId)) {
      newSelectedFloorPlan = [...selectedFloorPlans, floorId]
    } else if (selectedFloorPlans.includes(floorId)) {
      newSelectedFloorPlan = selectedFloorPlans.filter((d) => d !== floorId)
    }
    // =======================================================================================
    // =======================================================================================
    let newFilteredLocations = []
    let newFilteredFloorPlans = []
    if (selectedLocations.length === 0) {
      newFilteredFloorPlans = allFloorPlans.filter((d) => newSelectedFloorPlan.includes(d?.Id))
      if (newFilteredFloorPlans.length > 0) {
        newFilteredLocations = allLocations.filter((d) => newFilteredFloorPlans.map((floorplan) => floorplan.Location.Id).includes(d?.Id))
      } else if (newFilteredFloorPlans.length === 0) {
        newFilteredLocations = allLocations
      }
    } else if (selectedLocations.length > 0 && newFilteredFloorPlans.length === 0) {
      newFilteredLocations = allLocations
    }
    // =======================================================================================
    // =======================================================================================
    let newFilteredMeetingRooms = []
    if ((selectedLocations.length === 0 || selectedLocations.length > 0) && newSelectedFloorPlan.length > 0) {
      const tempSelectedFloorPlans = allFloorPlans.filter((d) => newSelectedFloorPlan.includes(d?.Id))
      tempSelectedFloorPlans.forEach((floorPlan) => {
        if (floorPlan?.FloorplanMeetingRooms && floorPlan?.FloorplanMeetingRooms.length > 0) {
          floorPlan?.FloorplanMeetingRooms.forEach((meetingRoom) => {
            newFilteredMeetingRooms.push({ ...meetingRoom, Id: meetingRoom?.RoomId })
          })
        }
      })
    }
    if (selectedLocations.length === 0 && newSelectedFloorPlan.length === 0) {
      newFilteredMeetingRooms = allMeetingRooms && allMeetingRooms.length > 0 ? allMeetingRooms : []
    }
    if (selectedLocations.length > 0 && newSelectedFloorPlan.length === 0) {
      newFilteredMeetingRooms = allMeetingRooms && allMeetingRooms.length > 0 ?
        allMeetingRooms.filter((d) => selectedLocations.includes(d?.Location?.Id)) : []
    }
    let newSelectedMeetingRooms = []
    newFilteredMeetingRooms.forEach((meetingRoom) => {
      selectedMeetingRooms.includes(meetingRoom?.Id) && newSelectedMeetingRooms.push(meetingRoom?.Id)
    })
    // =======================================================================================
    // =======================================================================================
    let newFilteredAreas = []
    if ((selectedLocations.length === 0 || selectedLocations.length > 0) && newSelectedFloorPlan.length > 0) {
      const tempSelectedFloorPlans = allFloorPlans.filter((d) => newSelectedFloorPlan.includes(d?.Id))
      tempSelectedFloorPlans.forEach((floorPlan) => {
        if (floorPlan?.FloorplanAreas && floorPlan?.FloorplanAreas.length > 0) {
          floorPlan?.FloorplanAreas.forEach((area) => {
            newFilteredAreas.push(area?.Area)
          })
        }
      })
    }
    if (selectedLocations.length === 0 && newSelectedFloorPlan.length === 0) {
      newFilteredAreas = allAreas && allAreas.length > 0 ? allAreas : []
    }
    if (selectedLocations.length > 0 && newSelectedFloorPlan.length === 0) {
      newFilteredAreas = allAreas && allAreas.length > 0 ?
        allAreas.filter((d) => selectedLocations.includes(d?.Location?.Id)) : []
    }
    let newSelectedAreas = []
    newFilteredAreas.forEach((area) => {
      selectedAreas.includes(area?.Id) && newSelectedAreas.push(area?.Id)
    })
    // =======================================================================================
    // =======================================================================================
    setSelectedFloorPlans(newSelectedFloorPlan)
    setSelectedAreas(newSelectedAreas)
    setSelectedMeetingRooms(newSelectedMeetingRooms)

    setFilteredLocations(sortResponseByKey(newFilteredLocations, "Name"))
    setFilteredAreas(sortResponseByKey(newFilteredAreas, "Name"))
    setFilteredMeetingRooms(sortResponseByKey(newFilteredMeetingRooms, "Name"))
  }

  const handleOnMeetingClick = (meetingRoomId, selectedLocations, selectedFloorPlans, selectedMeetingRooms) => {
    let newSelectedMeetingRooms = []
    if (!selectedMeetingRooms.includes(meetingRoomId)) {
      newSelectedMeetingRooms = [...selectedMeetingRooms, meetingRoomId]
    } else if (selectedMeetingRooms.includes(meetingRoomId)) {
      newSelectedMeetingRooms = selectedMeetingRooms.filter((d) => d !== meetingRoomId)
    }
    // =======================================================================================
    // =======================================================================================
    const tempFilteredMeetingRooms = allMeetingRooms && allMeetingRooms.length > 0 ?
      allMeetingRooms.filter((d) => newSelectedMeetingRooms.includes(d?.Id)) : []
    const tempFilteredMeetingRoomsLocationIds = allMeetingRooms && allMeetingRooms.length > 0 ?
      allMeetingRooms.filter((d) => newSelectedMeetingRooms.includes(d?.Id)).map((d) => d?.Location?.Id) : []
    // =======================================================================================
    // =======================================================================================
    let newFilteredLocations = []
    if ((selectedLocations.length === 0 || selectedFloorPlans.length === 0) && newSelectedMeetingRooms.length > 0) {
      allLocations.forEach((location) => {
        tempFilteredMeetingRoomsLocationIds.includes(location?.Id) && !newFilteredLocations.includes(location?.Id) &&
          newFilteredLocations.push(location)
      })
    } else if (newSelectedMeetingRooms.length === 0) {
      newFilteredLocations = allLocations && allLocations.length > 0 ? allLocations : []
    }
    let newSelectedLocations = []
    newFilteredLocations.forEach((location) => {
      selectedLocations.includes(location?.Id) && newSelectedLocations.push(location?.Id)
    })
    // =======================================================================================
    // =======================================================================================
    let newFilteredFloorPlans = []
    if ((selectedLocations.length === 0 || selectedFloorPlans.length === 0) && newSelectedMeetingRooms.length > 0) {
      const tempSelectedMeetingFloorPlans = allFloorPlans && allFloorPlans.length > 0 ?
        allFloorPlans.filter((d) => newFilteredLocations && newFilteredLocations.length > 0 ?
          newFilteredLocations.map((d) => d?.Id).includes(d?.Location?.Id) : []) : []

      newSelectedMeetingRooms.forEach((meetingRoom) => {
        tempSelectedMeetingFloorPlans.forEach((floorPlan) => {
          if (floorPlan?.FloorplanMeetingRooms && floorPlan?.FloorplanMeetingRooms.length > 0) {
            if (floorPlan?.FloorplanMeetingRooms.map((d) => d?.RoomId).includes(meetingRoom) &&
              !newFilteredFloorPlans.includes(floorPlan)) {
              newFilteredFloorPlans.push(floorPlan)
            }
          }
        })
      })
    } else if (newSelectedMeetingRooms.length === 0) {
      if (newSelectedLocations.length > 0) {
        allFloorPlans.forEach((floorPlan) => {
          newSelectedLocations.includes(floorPlan?.Location?.Id) && newFilteredFloorPlans.push(floorPlan)
        })
      } else if (newSelectedLocations.length === 0) {
        newFilteredFloorPlans = allFloorPlans && allFloorPlans.length > 0 ? allFloorPlans : []
      }
    }
    let newSelectedFloorPlans = []
    newFilteredFloorPlans.forEach((floorPlan) => {
      selectedFloorPlans.includes(floorPlan?.Id) && newSelectedFloorPlans.push(floorPlan?.Id)
    })
    // =======================================================================================
    // =======================================================================================

    setSelectedLocation(newSelectedLocations)
    setSelectedFloorPlans(newSelectedFloorPlans)
    setSelectedMeetingRooms(newSelectedMeetingRooms)

    setFilteredLocations(newFilteredLocations)
    setFilteredFloorPlans(newFilteredFloorPlans)
  }

  const handleOnAreaClick = (areaId, selectedLocations, selectedFloorPlans, selectedAreas) => {
    let newSelectedAreas = []
    if (!selectedAreas.includes(areaId)) {
      newSelectedAreas = [...selectedAreas, areaId]
    } else if (selectedAreas.includes(areaId)) {
      newSelectedAreas = selectedAreas.filter((d) => d !== areaId)
    }
    // =======================================================================================
    // =======================================================================================
    const tempFilteredAreas = allAreas && allAreas.length > 0 ?
      allAreas.filter((d) => newSelectedAreas.includes(d?.Id)) : []
    const tempFilteredAreasLocationIds = allAreas && allAreas.length > 0 ?
      allAreas.filter((d) => newSelectedAreas.includes(d?.Id)).map((d) => d?.Location?.Id) : []
    // =======================================================================================
    // =======================================================================================
    let newFilteredLocations = []
    if ((selectedLocations.length === 0 || selectedFloorPlans.length === 0) && newSelectedAreas.length > 0) {
      allLocations.forEach((location) => {
        tempFilteredAreasLocationIds.includes(location?.Id) && !newFilteredLocations.includes(location?.Id) &&
          newFilteredLocations.push(location)
      })
    } else if (newSelectedAreas.length === 0) {
      newFilteredLocations = allLocations && allLocations.length > 0 ? allLocations : []
    }
    let newSelectedLocations = []
    newFilteredLocations.forEach((location) => {
      selectedLocations.includes(location?.Id) && newSelectedLocations.push(location?.Id)
    })
    // =======================================================================================
    // =======================================================================================
    let newFilteredFloorPlans = []
    if ((selectedLocations.length === 0 || selectedFloorPlans.length === 0) && newSelectedAreas.length > 0) {
      const tempSelectedAreasFloorPlans = allFloorPlans && allFloorPlans.length > 0 ?
        allFloorPlans.filter((d) => newFilteredLocations && newFilteredLocations.length > 0 ?
          newFilteredLocations.map((d) => d?.Id).includes(d?.Location?.Id) : []) : []

      newSelectedAreas.forEach((area) => {
        tempSelectedAreasFloorPlans.forEach((floorPlan) => {
          if (floorPlan?.FloorplanAreas && floorPlan?.FloorplanAreas.length > 0) {
            if (floorPlan?.FloorplanAreas.map((d) => d?.Area?.Id).includes(area) && !newFilteredFloorPlans.includes(floorPlan)) {
              newFilteredFloorPlans.push(floorPlan)
            }
          }
        })
      })
    } else if (newSelectedAreas.length === 0) {
      if (newSelectedLocations.length > 0) {
        allFloorPlans.forEach((floorPlan) => {
          newSelectedLocations.includes(floorPlan?.Location?.Id) && newFilteredFloorPlans.push(floorPlan)
        })
      } else if (newSelectedLocations.length === 0) {
        newFilteredFloorPlans = allFloorPlans && allFloorPlans.length > 0 ? allFloorPlans : []
      }
    }
    let newSelectedFloorPlans = []
    newFilteredFloorPlans.forEach((floorPlan) => {
      selectedFloorPlans.includes(floorPlan?.Id) && newSelectedFloorPlans.push(floorPlan?.Id)
    })
    // =======================================================================================
    // =======================================================================================

    setSelectedLocation(newSelectedLocations)
    setSelectedFloorPlans(newSelectedFloorPlans)
    setSelectedAreas(newSelectedAreas)

    setFilteredLocations(newFilteredLocations)
    setFilteredFloorPlans(newFilteredFloorPlans)
  }

  const handleCostCenterClick = (costCenter, selectedCostCenter) => {
    let newSelectedCostCenter = []
    if (!selectedCostCenter.includes(costCenter)) {
      newSelectedCostCenter = [...selectedCostCenter, costCenter]
    } else if (selectedCostCenter.includes(costCenter)) {
      newSelectedCostCenter = selectedCostCenter.filter((d) => d !== costCenter)
    }
    setSelectedCostCenter(newSelectedCostCenter)
  }

  const handleDepartmentsClick = (department, selectedDepartment) => {
    let newSelectedDepartments = []
    if (!selectedDepartment.includes(department)) {
      newSelectedDepartments = [...selectedDepartment, department]
    } else if (selectedDepartment.includes(department)) {
      newSelectedDepartments = selectedDepartment.filter((d) => d !== department)
    }
    setSelectedDepartments(newSelectedDepartments)
  }

  const handleOnCompanyClick = (company, selectedCompanies) => {
    console.log(company, selectedCompanies)
    let newSelectedCompanies = []
    if (!selectedCompanies.includes(company)) {
      newSelectedCompanies = [...selectedCompanies, company]
    } else if (selectedCompanies.includes(company)) {
      newSelectedCompanies = selectedCompanies.filter((d) => d !== company)
    }
    setSelectedCompanies(newSelectedCompanies)
  }

  const handleProductsClick = (product, selectedProducts) => {
    let newSelectedProducts = []
    if (!selectedProducts.includes(product)) {
      newSelectedProducts = [...selectedProducts, product]
    } else if (selectedProducts.includes(product)) {
      newSelectedProducts = selectedProducts.filter((d) => d !== product)
    }
    setSelectedProducts(newSelectedProducts)
  }

  const GetLocations = async () => {
    await getAdminLocation().then((response) => {
      let tempAllAreas = []
      if (typeof response === "object" && response?.length > 0) {
        setAllLocations(sortResponseByKey(response, "Name"))
        setFilteredLocations(sortResponseByKey(response, "Name"))
        response?.forEach((location) => {
          location?.Areas && location?.Areas?.length > 0 && location?.Areas?.forEach((area) => {
            tempAllAreas.push({ ...area, Location: { Id: location?.Id } })
          })
        })
      } else {
        tempAllAreas = []
      }
      setAllAreas(sortResponseByKey(tempAllAreas, "Name"))
      setFilteredAreas(sortResponseByKey(tempAllAreas, "Name"))
    }).catch((error) => {
      console.warn(error)
    })
  }

  const GetFloorPlans = async () => {
    await getAdminFloorPlans().then((response) => {
      if (typeof response === "object" && response?.length > 0) {
        dispatch(changeAllFloorPlans(sortResponseByKey(response, "Name")))
        setAllFloorPlans(sortResponseByKey(response, "Name"))
        setFilteredFloorPlans(sortResponseByKey(response, "Name"))
      }
    }).catch((error) => {
      console.warn(error)
    })
  }

  const GetAllMeetingRooms = async () => {
    await getMeetingRooms().then((response) => {
      if (typeof response === "object" && response?.length > 0) {
        setAllMeetingRooms(sortResponseByKey(response, "Name"))
        setFilteredMeetingRooms(sortResponseByKey(response, "Name"))
      }
    }).catch((error) => {
      setAllMeetingRooms([])
      setFilteredMeetingRooms([])
      console.warn(error)
    })
  }

  const GetAllDepartments = async () => {
    await getAllDepartments().then((response) => {
      if (typeof response === "object" && response?.length > 0) {
        dispatch(changeAllDepartments(sortResponseByKey(response, "Name")))
        setAllDepartments(sortResponseByKey(response, "Name"))
      }
    }).catch((error) => {
      setAllDepartments([])
      console.warn(error)
    })
  }

  const GetAllCompanies = async () => {
    await getAllCompanies().then((response) => {
      if (typeof response === "object" && response?.length > 0) {
        setAllCompanies(sortResponseByKey(response, "Name"))
      }
    }).catch((error) => {
      setAllCompanies([])
      console.warn(error)
    })
  }

  const GetAllProducts = async () => {
    await getAllProducts().then((response) => {
      if (typeof response === "object" && response?.length > 0) {
        setAllProducts(sortResponseByKey(response, "Name"))
      }
    }).catch((error) => {
      setAllProducts([])
      console.warn(error)
    })
  }

  const GetAdminDepartments = async () => {
    await getAdminDepartments().then((response) => {
      if (typeof response === "object" && response?.length > 0) {
        dispatch(changeAdminDepartments(sortResponseByKey(response, "Name")))
        setAdminDepartments(sortResponseByKey(response, "Name"))
      }
    }).catch((error) => {
      setAdminDepartments([])
      console.warn(error)
    })
  }

  useEffect(() => {
    setSelectedProducts(ApiObjectState?.productsIds ?? [])
    setSelectedAreas(ApiObjectState?.areaIds ?? [])
    setSelectedCompanies(ApiObjectState?.companiesIds ?? [])
    setSelectedCostCenter(ApiObjectState?.costCenterIds ?? [])
    setSelectedFloorPlans(ApiObjectState?.floorplanIds ?? [])
    setSelectedMeetingRooms(ApiObjectState?.meetingRoomIds ?? [])
    setSelectedDepartments(ApiObjectState?.departments ?? [])
  }, [ApiObjectState])

  useEffect(() => {
    dispatch(changeAPIObject({
      fromDate: moment(inputDate?.From).format("YYYY-MM-DD"),
      toDate: moment(inputDate?.To).format("YYYY-MM-DD"),
      locationIds: selectedLocations,
      floorplanIds: selectedFloorPlans,
      companiesIds: selectedCompanies,
      productsIds: selectedProducts,
      meetingRoomIds: selectedMeetingRooms,
      areaIds: selectedAreas,
      costCenterIds: selectedCostCenter,
      departments: selectedDepartments,
    }))
  }, [inputDate, selectedLocations, selectedFloorPlans, selectedAreas, selectedMeetingRooms, selectedCostCenter, selectedProducts, selectedCompanies, selectedDepartments])

  useEffect(() => {
    hasMeetingRoomPermission && GetAllMeetingRooms()
  }, [hasMeetingRoomPermission])

  useEffect(() => {
    if (hasCateringPermission) {
      GetAllDepartments()
      GetAllProducts()
      GetAllCompanies()
    }
  }, [hasCateringPermission])

  useEffect(() => {
    if (activePanel === "WorkTime") {
      // setInputDate({ From: moment().subtract(1, "isoWeek").startOf("isoWeek")._d, To: moment().subtract(1, "isoWeek").endOf("isoWeek")._d })
      // setInputDate({ From: moment().subtract(7, "d").startOf("date")._d, To: moment().format("YYYY-MM-DD") })
      setInputDate({
        From: moment().startOf("isoWeek").toDate(),
        To: moment().endOf("isoWeek").toDate(),
      });
    } else {
      if (JSON.stringify({ From: moment().subtract(1, "M").format("YYYY-MM-DD"), To: moment().format("YYYY-MM-DD") }) !== JSON.stringify(inputDate)) {
        setInputDate({ From: moment().subtract(1, "M").format("YYYY-MM-DD"), To: moment().format("YYYY-MM-DD") })
      }
    }

    if (activePanel === "Catering") {
      setFilteredLocations(allLocations)
      setFilteredFloorPlans(allFloorPlans)
      setFilteredMeetingRooms(allMeetingRooms)
      setFilteredAreas(allAreas)

      setSelectedLocation([])
      setSelectedFloorPlans([])
      setSelectedMeetingRooms([])
      setSelectedAreas([])
      setSelectedCostCenter([])
      setSelectedProducts([])
    }

  }, [activePanel])

  useEffect(() => {
    GetFloorPlans()
    GetLocations()
    GetAdminDepartments()
  }, [])

  useEffect(() => {
    if (moment(inputDate?.To).isBefore(moment(inputDate?.From))) {
      setInputDate((Prev) => ({ ...Prev, To: inputDate?.From }))
    }
  }, [inputDate?.From])

  return (
    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 filterSec">
      <div className="card p-4">
        {/* ========================================= Period Start ========================================= */}
        {/* ========================================= Period Start ========================================= */}
        {/* ========================================= Period Start ========================================= */}
        <h4 className="mb-3">Select period</h4>

        <h4 className="mb-2"><small>From</small></h4>
        <div className="has-validation mb-3">
          <ReactDatePicker
            type="date"
            calendarStartDay={1}
            selected={moment(inputDate?.From)._d}
            onChange={(e) => e && setInputDate((Prev) => ({ ...Prev, From: e }))}
            // maxDate={moment(inputDate?.To)._d}
            dateFormat="dd.MM.yyyy"
            className="form-control"
            placeholder="DD/MM/YYYY"
          />
        </div>

        <h4 className="mb-2"><small>To</small></h4>
        <div className="has-validation">
          <ReactDatePicker
            type="date"
            calendarStartDay={1}
            selected={moment(inputDate?.To)._d}
            onChange={(e) => e && setInputDate((Prev) => ({ ...Prev, To: e }))}
            minDate={moment(inputDate?.From)._d}
            // maxDate={moment()._d}
            dateFormat="dd.MM.yyyy"
            className="form-control"
            placeholder="DD/MM/YYYY"
          />
        </div>
        {/* ----------------------------------------- Period End ----------------------------------------- */}
        {/* ========================================= Company Start ========================================= */}
        {/* ========================================= Company Start ========================================= */}
        {/* ========================================= Company Start ========================================= */}
        {activePanel === "Catering" && (
          <>
            <hr className="mt-4 mb-4" />
            <h4 className="mb-2">Company</h4>
            {allCompanies?.length > 0 && (
              allCompanies?.map((company, companyIndex) => companyIndex < companiesCount && (
                <div key={companyIndex} className="d-flex align-items-center mb-1" >
                  <label
                    onClick={() => handleOnCompanyClick(company?.Id, selectedCompanies)}

                    className={`w-100 py-1 px-0 sidebar-filter ${selectedCompanies?.includes(company?.Id) ? "selected" : ""}`}
                  >
                    {company?.Name ? company?.Name : "--"}
                  </label>
                </div>
              ))
            )}

            {companiesCount < allCompanies?.length ? (
              <label onClick={() => setCompaniesCount(allCompanies?.length)} className="mt-15 show-label sidebar-filter">
                Show ({allCompanies?.length - 4}) more
              </label>
            ) : allCompanies?.length > 4 && (
              <label onClick={() => setCompaniesCount(4)} className="mt-15 show-label mt-2 sidebar-filter">
                Show less
              </label>
            )}
          </>
        )}
        {/* ----------------------------------------- Company End ----------------------------------------- */}


        {/* ========================================= Location Start ========================================= */}
        {/* ========================================= Location Start ========================================= */}
        {/* ========================================= Location Start ========================================= */}
        {activePanel !== "WorkTime" && (
          <>
            <hr className="mt-4 mb-4" />
            <h4 className="mb-2">Locations</h4>
            {filteredLocations?.length > 0 && (
              filteredLocations?.map((location, locationIndex) => locationIndex < locationCount && (
                <div key={locationIndex} className="d-flex align-items-center mb-1" >
                  <label
                    onClick={() => handleOnLocationClick(location?.Id, selectedLocations, selectedFloorPlans, selectedMeetingRooms, selectedAreas)}
                    className={`w-100 py-1 px-0 sidebar-filter ${selectedLocations?.includes(location?.Id) ? "selected" : ""}`}
                  >
                    {location?.Name ? location?.Name : "--"}
                  </label>
                </div>
              ))
            )}

            {locationCount < filteredLocations?.length ? (
              <label onClick={() => setLocationCount(filteredLocations?.length)} className="mt-15 show-label sidebar-filter">
                Show ({filteredLocations?.length - 4}) more
              </label>
            ) : filteredLocations?.length > 4 && (
              <label onClick={() => setLocationCount(4)} className="mt-15 show-label mt-2 sidebar-filter">
                Show less
              </label>
            )}
          </>
        )}
        {/* ----------------------------------------- Location End ----------------------------------------- */}



        {/* ========================================= Floor Plan Start ========================================= */}
        {/* ========================================= Floor Plan Start ========================================= */}
        {/* ========================================= Floor Plan Start ========================================= */}
        {activePanel !== "WorkTime" && activePanel !== "Catering" && (
          <>
            <hr className="mt-4 mb-4" />
            <h4 className="mb-2">Floor plans</h4>
            {filteredFloorPlans?.length > 0 && (
              filteredFloorPlans?.map((floorPlan, floorPlanIndex) => floorPlanIndex < floorCount && (
                <div key={floorPlanIndex} className="d-flex align-items-center mb-1">
                  <label
                    onClick={() => handleOnFloorClick(floorPlan?.Id, selectedLocations, selectedFloorPlans, selectedMeetingRooms, selectedAreas)}
                    className={`w-100 py-1 px-0 sidebar-filter ${selectedFloorPlans?.includes(floorPlan?.Id) ? "selected" : ""}`}
                  >
                    {floorPlan?.Name ? floorPlan?.Name : "--"}
                  </label>
                </div>
              ))
            )}

            {floorCount < filteredFloorPlans?.length ? (
              <label onClick={() => setFloorCount(filteredFloorPlans?.length)} className="mt-15 show-label sidebar-filter">
                Show ({filteredFloorPlans?.length - 4}) more
              </label>
            ) : filteredFloorPlans?.length > 4 && (
              <label onClick={() => setFloorCount(4)} className="mt-15 show-label mt-2 sidebar-filter">
                Show less
              </label>
            )}
          </>
        )}
        {/* ----------------------------------------- Floor Plan End ----------------------------------------- */}



        {/* ========================================= MeetingRooms Start ========================================= */}
        {/* ========================================= MeetingRooms Start ========================================= */}
        {/* ========================================= MeetingRooms Start ========================================= */}
        {activePanel === "Meeting" && activePanel !== "Catering" && (
          <>
            <hr className="mt-4 mb-4" />
            <h4 className="mb-2">Meeting Rooms</h4>
            {filteredMeetingRooms?.length > 0 && (
              filteredMeetingRooms?.map((meetingRoom, meetingRoomIndex) => meetingRoomIndex < filteredMeetingRoomsCount && (
                <div key={meetingRoomIndex} className="d-flex align-items-center mb-1">
                  <label
                    onClick={() => handleOnMeetingClick(meetingRoom?.Id, selectedLocations, selectedFloorPlans, selectedMeetingRooms)}
                    className={`w-100 py-1 px-0 sidebar-filter ${selectedMeetingRooms?.includes(meetingRoom?.Id) ? "selected" : ""}`}
                  >
                    {meetingRoom?.Name ? meetingRoom?.Name : "--"}
                  </label>
                </div>
              ))
            )}

            {filteredMeetingRoomsCount < filteredMeetingRooms?.length ? (
              <label onClick={() => setFilteredMeetingRoomsCount(filteredMeetingRooms?.length)} className="mt-15 show-label sidebar-filter">
                Show ({filteredMeetingRooms?.length - 4}) more
              </label>
            ) : filteredMeetingRooms?.length > 4 && (
              <label onClick={() => setFilteredMeetingRoomsCount(4)} className="mt-15 show-label mt-2 sidebar-filter">
                Show less
              </label>
            )}
          </>
        )}
        {/* ----------------------------------------- MeetingRooms End ----------------------------------------- */}



        {/* ========================================= Areas Start ========================================= */}
        {/* ========================================= Areas Start ========================================= */}
        {/* ========================================= Areas Start ========================================= */}
        {activePanel === "Desk" && activePanel !== "Catering" && (
          <>
            <hr className="mt-4 mb-4" />
            <h4 className="mb-2">Areas</h4>
            {filteredAreas?.length > 0 && (
              filteredAreas?.map((area, areaIndex) => areaIndex < areaCount && (
                <div key={areaIndex} className="d-flex align-items-center mb-1">
                  <label
                    onClick={() => handleOnAreaClick(area?.Id, selectedLocations, selectedFloorPlans, selectedAreas)}
                    className={`w-100 py-1 px-0 sidebar-filter ${selectedAreas?.includes(area?.Id) ? "selected" : ""}`}
                  >
                    {area?.Name ? area?.Name : "--"}
                  </label>
                </div>
              ))
            )}

            {areaCount < filteredAreas?.length ? (
              <label onClick={() => setAreaCount(filteredAreas?.length)} className="mt-15 show-label sidebar-filter">
                Show ({filteredAreas?.length - 4}) more
              </label>
            ) : filteredAreas?.length > 4 && (
              <label onClick={() => setAreaCount(4)} className="mt-15 show-label mt-2 sidebar-filter">
                Show less
              </label>
            )}
          </>
        )}
        {/* ----------------------------------------- Areas End ----------------------------------------- */}


        {/* ========================================= Departments Start ========================================= */}
        {/* ========================================= Departments Start ========================================= */}
        {/* ========================================= Departments Start ========================================= */}
        {(activePanel === "Desk") && (
          <>
            <hr className="mt-4 mb-4" />
            <h4 className="mb-2">Departments</h4>
            {adminDepartments?.length > 0 && (
              adminDepartments?.map((department, departmentIndex) => departmentIndex < departmentCount && (
                <div key={departmentIndex} className="d-flex align-items-center mb-1">
                  <label
                    onClick={() => handleDepartmentsClick(department, selectedDepartments)}
                    className={`w-100 py-1 px-0 sidebar-filter  ${selectedDepartments?.includes(department) ? "selected" : ""}`}
                  >
                    {department ?? "--"}
                  </label>
                </div>
              ))
            )}

            {departmentCount < adminDepartments?.length ? (
              <label onClick={() => setDepartmentCount(adminDepartments?.length)} className="mt-15 show-label sidebar-filter">
                Show ({adminDepartments?.length - 4}) more
              </label>
            ) : adminDepartments?.length > 4 && (
              <label onClick={() => setDepartmentCount(4)} className="mt-15 show-label mt-2 sidebar-filter">
                Show less
              </label>
            )}
            {/* ----------------------------------------- Departments End ----------------------------------------- */}
          </>
        )}
        {/* ----------------------------------------- Departments End ----------------------------------------- */}

        {/* ========================================= Cost center Start ========================================= */}
        {/* ========================================= Cost center Start ========================================= */}
        {/* ========================================= Cost center Start ========================================= */}
        {activePanel === "Catering" && (
          <>
            <hr className="mt-4 mb-4" />
            <h4 className="mb-2">Costcenter</h4>
            {allDepartments?.length > 0 && (
              allDepartments?.filter((d) => d?.Active)?.map((department, departmentIndex) => departmentIndex < costCenterCount && (
                <div key={departmentIndex} className="d-flex align-items-center mb-1">
                  <label
                    onClick={() => handleCostCenterClick(department?.Id, selectedCostCenter)}
                    className={`w-100 py-1 px-0 sidebar-filter  ${selectedCostCenter?.includes(department?.Id) ? "selected" : ""}`}
                  >
                    {department?.Name ? department?.Name : "--"}
                  </label>
                </div>
              ))
            )}

            {costCenterCount < allDepartments?.length ? (
              <label onClick={() => setCostCenterCount(allDepartments?.length)} className="mt-15 show-label sidebar-filter">
                Show ({allDepartments?.length - 4}) more
              </label>
            ) : allDepartments?.length > 4 && (
              <label onClick={() => setCostCenterCount(4)} className="mt-15 show-label mt-2 sidebar-filter">
                Show less
              </label>
            )}
            {/* ----------------------------------------- Departments End ----------------------------------------- */}
          </>
        )}
        {/* ----------------------------------------- Cost center End ----------------------------------------- */}

        {/* ========================================= Products Start ========================================= */}
        {/* ========================================= Products Start ========================================= */}
        {/* ========================================= Products Start ========================================= */}
        {activePanel === "Catering" && (
          <>
            <hr className="mt-4 mb-4" />
            <h4 className="mb-2">Products</h4>
            {allProducts?.length > 0 && (
              allProducts?.map((product, productIndex) => productIndex < productCount && (
                <div key={productIndex} className="d-flex align-items-center mb-1">
                  <label
                    onClick={() => handleProductsClick(product?.Id, selectedProducts)}
                    className={`w-100 py-1 px-0 sidebar-filter  ${selectedProducts?.includes(product?.Id) ? "selected" : ""}`}
                  >
                    {product?.Name ? product?.Name : "--"}
                  </label>
                </div>
              ))
            )}

            {productCount < allProducts?.length ? (
              <label onClick={() => setProductCount(allProducts?.length)} className="mt-15 show-label sidebar-filter">
                Show ({allProducts?.length - 4}) more
              </label>
            ) : allProducts?.length > 4 && (
              <label onClick={() => setProductCount(4)} className="mt-15 show-label mt-2 sidebar-filter">
                Show less
              </label>
            )}
            {/* ----------------------------------------- Products End ----------------------------------------- */}
          </>
        )}
        {/* ----------------------------------------- Products End ----------------------------------------- */}
      </div>
    </div >
  )
}
