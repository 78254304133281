import React from "react"
import { changeActivePanel } from "../../store/slices/APIResponseSlice"
import { useDispatch, useSelector } from "react-redux"
import MeetingRooms from "../MeetingRooms/MeetingRooms"
// import WorkTime from "../WorkTime/WorkTime"
import WorkTimeNewDesign from "../WorkTimeNewDesign/WorkTimeNewDesign"
import Catering from "../Catering/Catering"
import SideBar from "../SideBar/SideBar"
import Desk from "../Desk/Desk"
import "./Home.css"

export default function Home() {
  const activePanel = useSelector((state) => state.APIResponse.ActivePanel) // "Meeting" || "Desk" || "WorkTime" || "Catering"
  const hasMeetingRoomPermission = useSelector((state) => state.APIResponse.EnableMeetingRoom)
  const hasDeskBookingPermission = useSelector((state) => state.APIResponse.EnableDeskBooking)
  const hasWorkTimePermission = useSelector((state) => state.APIResponse.EnableWorkTime)
  const hasCateringPermission = useSelector((state) => state.APIResponse.EnableCanteen)

  const dispatch = useDispatch()

  return (
    <main id="main" className="main overflow-hidden">
      <section className="section">
        <div className="row">
          <SideBar />

          <div className="col-xl-10 col-lg-9 col-md-8 col-sm-12">
            <div className="card">
              <div className="card-body p-4">
                <ul className="nav nav-tabs">
                  {hasMeetingRoomPermission && hasMeetingRoomPermission === true &&
                    <li className="nav-item">
                      <button onClick={() => dispatch(changeActivePanel("Meeting"))} className={`nav-link ${activePanel === "Meeting" ? "active" : ""}`}>
                        <span>Meeting Rooms</span>
                      </button>
                    </li>
                  }
                  {hasDeskBookingPermission && hasDeskBookingPermission === true &&
                    <li className="nav-item">
                      <button onClick={() => dispatch(changeActivePanel("Desk"))} className={`nav-link ${activePanel === "Desk" ? "active" : ""}`}>
                        <span>Desks</span>
                      </button>
                    </li>
                  }
                  {hasWorkTimePermission && hasWorkTimePermission === true &&
                    <li className="nav-item">
                      <button onClick={() => dispatch(changeActivePanel("WorkTime"))} className={`nav-link ${activePanel === "WorkTime" ? "active" : ""}`}>
                        <span>WorkTime</span>
                      </button>
                    </li>
                  }
                  {hasCateringPermission && hasCateringPermission === true &&
                    <li className="nav-item">
                      <button onClick={() => dispatch(changeActivePanel("Catering"))} className={`nav-link ${activePanel === "Catering" ? "active" : ""}`}>
                        <span>Catering</span>
                      </button>
                    </li>
                  }
                </ul>
              </div>
            </div>

            {hasMeetingRoomPermission && activePanel === "Meeting" && <MeetingRooms />}

            {hasDeskBookingPermission && activePanel === "Desk" && <Desk />}

            {/* {hasWorkTimePermission && activePanel === "WorkTime" && <WorkTime />} */}
            {hasWorkTimePermission && activePanel === "WorkTime" && <WorkTimeNewDesign />}

            {hasCateringPermission && activePanel === "Catering" && <Catering />}
          </div>
        </div>

        {/* <div className="card p-4 w-100"></div> */}
      </section>
    </main >
  )
}
