import React, { useCallback, useEffect, useState } from "react"
import { BrowserRouter } from "react-router-dom"
import { InteractionRequiredAuthError, InteractionStatus, } from "@azure/msal-browser"
import { AuthenticatedTemplate, useIsAuthenticated, useMsal, } from "@azure/msal-react"
import { loginRequest } from "./authConfig"
import { useDispatch, useSelector } from "react-redux"
import { changeAccessToken, changeUserName, changeUserEmail, } from "./store/slices/MSALSlice"
import { changeActivePanel, changeAppThemeData, changeEnableCanteen, changeEnableDeskBooking, changeEnableMeetingRoom, changeEnableWorkTime } from "./store/slices/APIResponseSlice"
import { getCurrentUser } from "./services/ApiServices"
import { toast } from "react-hot-toast"
import { accessRoles, scope } from "./const"
import Header from "./components/Header/Header"
import Home from "./components/Home/Home"
import ErrorPage from "./components/ErrorPage/Error"
import moment from "moment"
import Loader from "./components/Loader/Loader"

export default function App() {
  const accessToken = useSelector((state) => state.MSALAuth.accessToken)
  const isAuthenticated = useIsAuthenticated()
  const { instance, accounts, inProgress } = useMsal()

  const [intervalTimeForAccessToken, setIntervalTimeForAccessToken] = useState(3600000)

  const [isLoading, setIsLoading] = useState(true)
  const [isAdmin, setIsAdmin] = useState(null)

  const [isAnalyticsShow, setIsAnalyticsShow] = useState(false)

  const dispatch = useDispatch()

  const handleLogin = useCallback(() => {
    instance.loginRedirect(loginRequest).catch((error) => console.warn(error))
  }, [instance])

  const setAccessTokenData = useCallback((accessTokenResponse) => {
    // Acquire token silent success
    const accessToken = accessTokenResponse.accessToken

    localStorage.setItem("token", accessToken)
    window.accessToken = accessToken
    window.expToken = accessTokenResponse.idTokenClaims.exp

    dispatch(changeAccessToken(accessToken))

    setIntervalTimeForAccessToken((moment(accessTokenResponse?.expiresOn).diff(moment()._d, "s") + 1) * 1000)
  }, [dispatch])

  // Create Shade of #Hex color (divide = 2)
  const getShadeOfColor = (hex, divide) => {
    // Converted #Hex to (R, G, B)
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)

    // Convert (R, G, B) to their respective shades
    const r2 = parseInt(r + (255 - r) / divide)
    const g2 = parseInt(g + (255 - g) / divide)
    const b2 = parseInt(b + (255 - b) / divide)

    // Convert (R, G, B) to (R => #Hex, B => #Hex, G => #Hex)
    const componentToHex = (c) => {
      var hex = c.toString(16)
      return hex.length === 1 ? "0" + hex : hex
    }

    return "#" + componentToHex(r2) + componentToHex(g2) + componentToHex(b2)
  }

  const getCurrentUserData = async () => {
    await getCurrentUser().then((response) => {
      setIsLoading(true)
      if (response?.Roles && response?.Roles?.some((role) => accessRoles?.includes(role))) {
        dispatch(changeUserName(response?.Name))
        dispatch(changeUserEmail(response?.Email))
        dispatch(changeAppThemeData({
          AppLogo: response?.AppLogo ? response?.AppLogo : "",
          Theme: response.Theme ? response.Theme : {},
        }))
        if (response?.EnableMeetingRoom) {
          dispatch(changeActivePanel("Meeting"))
        } else if (!response?.EnableMeetingRoom && response?.EnableDeskBooking) {
          dispatch(changeActivePanel("Desk"))
        } else if (!response?.EnableMeetingRoom && !response?.EnableDeskBooking && response?.FeatureEnableWorkTime) {
          dispatch(changeActivePanel("WorkTime"))
        } else if (!response?.EnableMeetingRoom && !response?.EnableDeskBooking && !response?.FeatureEnableWorkTime && response?.EnableCanteen) {
          dispatch(changeActivePanel("Catering"))
        }
        dispatch(changeEnableMeetingRoom(response?.EnableMeetingRoom))
        dispatch(changeEnableDeskBooking(response?.EnableDeskBooking))
        dispatch(changeEnableWorkTime(response?.FeatureEnableWorkTime))
        dispatch(changeEnableCanteen(response?.EnableCanteen))
        setIsAnalyticsShow(response?.FeatureEnableAnalytics)
        if (response?.Theme) {
          const root = document.querySelector(":root")
          root && root.style.setProperty("--app-theme-color", `#${response.Theme?.AppThemeColor}`)
          root && root.style.setProperty("--app-theme-50-color", getShadeOfColor(`#${response.Theme?.AppThemeColor}`, 1.3))
          root && root.style.setProperty("--app-theme-25-color", getShadeOfColor(getShadeOfColor(`#${response.Theme?.AppThemeColor}`, 1.3), 2))
        }
        setIsAdmin(true)
        setIsLoading(false)
      } else {
        dispatch(changeUserName(response?.Name ? response?.Name : ""))
        dispatch(changeUserEmail(response?.Email ? response?.Email : ""))
        setIsAdmin(false)
        setIsLoading(false)
      }
    }).catch((error) => {
      toast.error(error)
    })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const accessTokenRequest = { scopes: [scope], account: accounts[0] }

      if (inProgress === InteractionStatus.None) {
        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
          // Acquire token silent success
          const accessToken = accessTokenResponse.accessToken

          localStorage.setItem("token", accessToken)
          window.accessToken = accessToken
          window.expToken = accessTokenResponse.idTokenClaims.exp

          dispatch(changeAccessToken(accessToken))

          setIntervalTimeForAccessToken((moment(accessTokenResponse?.expiresOn).diff(moment()._d, "s") + 1) * 1000)
        }).catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest)
          }
          console.warn(error)
        })
      }
    }, intervalTimeForAccessToken)

    return () => { clearInterval(interval) }
  }, [accounts, inProgress, instance, setAccessTokenData, intervalTimeForAccessToken, dispatch])

  useEffect(() => {
    accessToken && getCurrentUserData()
  }, [accessToken])

  useEffect(() => {
    dispatch(changeUserName(isAuthenticated ? accounts[0]?.name : ""))
    dispatch(changeUserEmail(isAuthenticated ? accounts[0]?.username : ""))
  }, [dispatch, isAuthenticated, accounts])

  useEffect(() => {
    instance.handleRedirectPromise().then((response) => {
      if (response) {
        setAccessTokenData(response)
      } else if (accounts?.length === 0) {
        handleLogin()
      } else if (accounts?.length === 1) {
        const accessTokenRequest = { scopes: [scope], account: accounts[0] }

        if (inProgress === InteractionStatus.None) {
          instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
            setAccessTokenData(accessTokenResponse)
          }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance.acquireTokenRedirect(accessTokenRequest)
            }
            console.warn(error)
          })
        }
      } else if (accounts?.length > 1) {
        // Add your account choosing logic here
        const accessTokenRequest = { scopes: [scope], account: accounts[0] }

        if (inProgress === InteractionStatus.None) {
          instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
            setAccessTokenData(accessTokenResponse)
          }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance.acquireTokenRedirect(accessTokenRequest)
            }
            console.warn(error)
          })
        }
      }
    }).catch((error) => {
      console.warn(error)
    })
  }, [accounts, handleLogin, inProgress, instance, setAccessTokenData])

  return (
    <BrowserRouter>
      <AuthenticatedTemplate>
        <div style={{ height: "100vh", width: "100%", overflow: "auto" }}>
          {!isLoading
            ? accessToken && isAdmin
              ? isAnalyticsShow
                ? <>
                  <Header />
                  <Home />
                </>
                : <ErrorPage code={401} message={"You do not have permission to access this resource."} />
              : accessToken && isAdmin === false && <ErrorPage code={401} message={"You do not have permission to access this resource."} />
            : <div className="h-100 w-100">
              <Loader />
            </div>
          }
        </div>
      </AuthenticatedTemplate>
    </BrowserRouter>
  )
}
