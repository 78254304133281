import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  AppThemeData: {
    AppLogo: { FileName: "", Image: "" },
    Theme: {
      AppThemeColor: "",
      DeskBookingColor: "",
      MyDeskMeetingColor: "",
      TeamsMeetingColor: "",
      WorkFromHomeColor: "",
    },
  },
  AllFloorPlans: [],
  AllDepartments: [],
  AdminDepartments: [],
  ActivePanel: "",
  ApiObject: null,
  EnableMeetingRoom: false,
  EnableDeskBooking: false,
  EnableWorkTime: false,
  EnableCanteen: false,
};

export const APIResponseSlice = createSlice({
  name: "API",
  initialState,
  reducers: {
    changeAppThemeData: (state, action) => {
      state.AppThemeData = action.payload
    },
    changeAllFloorPlans: (state, action) => {
      state.AllFloorPlans = action.payload
    },
    changeAllDepartments: (state, action) => {
      state.AllDepartments = action.payload
    },
    changeAdminDepartments: (state, action) => {
      state.AdminDepartments = action.payload
    },
    changeActivePanel: (state, action) => {
      state.ActivePanel = action.payload
    },
    changeAPIObject: (state, action) => {
      state.ApiObject = action.payload
    },
    changeEnableMeetingRoom: (state, action) => {
      state.EnableMeetingRoom = action.payload
    },
    changeEnableDeskBooking: (state, action) => {
      state.EnableDeskBooking = action.payload
    },
    changeEnableWorkTime: (state, action) => {
      state.EnableWorkTime = action.payload
    },
    changeEnableCanteen: (state, action) => {
      state.EnableCanteen = action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  changeAppThemeData,
  changeAllFloorPlans,
  changeAllDepartments,
  changeAdminDepartments,
  changeActivePanel,
  changeAPIObject,
  changeEnableMeetingRoom,
  changeEnableDeskBooking,
  changeEnableWorkTime,
  changeEnableCanteen
} = APIResponseSlice.actions;

export default APIResponseSlice.reducer;
